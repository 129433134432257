import React, {useEffect} from 'react';

const Redirect = () => {

    useEffect(()=>{
        window.location = "https://www.campusbythesea.org";
    }, []);

  return (
    <div>
    </div>
  );
};

export default Redirect;
